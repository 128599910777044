<template>
    <div class="login-mobile">
        <div class="mobileloginform">
            <div class="form-header">
                <span style="margin-top: 8px">{{signInCIC}}</span>
            </div>

            <el-form class="formpadding" :rules="rules" ref="login" :model="form">
                <el-form-item class="account-box" :label="$t('ctUserCenter.userName')+':'" prop ="account" label-position="top">
                    <el-input  :placeholder="accountPlaceholder" v-model="form.account" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item class="password-box" :label="$t('CTRegister.passwordLabel')" prop="password" label-position="top">
                    <el-input :placeholder="passwordPlaceholder" show-password v-model="form.password" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item class="captcha-box"  v-if="showCaptcha">
                    <div id="captcha"></div>
                </el-form-item>

                <el-checkbox class="check" :label="checkContent" v-model="saveAccount"></el-checkbox>
                <el-button type="primary" class="loginbutton" :disabled="submitDisabled" @click="onSubmit()">{{buttonText}}</el-button>

                <div class="bottom-buttons">
                    <router-link to="/forgetMobile" class="link"  replace>{{forgetPassword}}</router-link>
                    <router-link :to='registerUrl' class="link" replace>{{haveNoneAccount}}</router-link>
                    <div class="link" @click="gotoPolicy()">{{policy}}</div>
                    <!--
                    <router-link to="https://www.cic-tp.com/en/protocol?id=SEC1810170927547812" class="link">{{policy}}</router-link>
                    -->
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import index from './index'
    export default index
</script>

<style lang="less" scoped>
    .login-mobile{
        /*background-color: red;*/
        padding: 20px;
        .mobileloginform{
            width: 100%;
            background: #FFFFFF;
            display: flex;
            flex-direction: column;

            .formpadding{
                /*padding: 0 20px 0 20px;*/
                /*background-color: greenyellow;*/
            }
            .form-title{
                height: 100px;
                border-bottom: 2px solid #0073FF;

                font-size: 30px;
                color: #333333;
                letter-spacing: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .form-header{
                height: 65px;
                color:#333333;
                font-size: 18px;
                font-weight: bold;
                display: flex;
                /*background-color: green;*/
            }

            .icon{
                margin:27px 5px 26px 17px;
                width: 20px;
                height: 20px;
            }
            .account-box{
                margin-top: 0px;
                width: 100%;
                height: 88px;
                /*display: flex;*/
            }

            .password-box{
                margin-top: 0px;
                width: 100%;
                height: 88px;
            }
            .captcha-box{
                margin-top: 30px;
                margin-bottom: 0px;
                width: 100%;
                height: 70px;
                display: flex;
            }
            .check{
                margin-top: 0px;
            }
            .loginbutton {
                margin-top: 30px;
                width: 100%;
                height: 50px;
                background-color: #0073FF;
                box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
                font-size: 18px;
                font-weight: bold;
            }
            .bottom-buttons{
                margin-top: 20px;
                margin-bottom:40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .link{
                    margin-top: 30px;
                    font-size: 14px;
                    color: #0073FF;
                    letter-spacing: 0;
                    cursor: pointer;
                }
            }
            .account-input{
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .account-input{
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }

        /*.loginform .account-box .el-input__inner{*/
        /*    !*padding-left: 35px;*!*/
        /*    !*height: 70px;*!*/
        /*    !*width: 620px;*!*/
        /*    background:url(../../../assets/images/user.png) no-repeat 0 0;*/
        /*    background-size: 20px 20px;*/
        /*    background-position: 20px 25px;*/
        /*    text-indent: 20px;*/
        /*    !* border: 1px solid #CAD3DE;*!*/
        /*}*/
        /*.loginform .password-box .el-input__inner{*/
        /*    padding-left: 35px;*/
        /*    height: 70px;*/
        /*    width: 620px;*/
        /*    background:url(../../../assets/images/lock.png) no-repeat 0 0;*/
        /*    background-size: 20px 20px;*/
        /*    background-position: 20px 25px;*/
        /*    text-indent: 20px;*/
        /*    !* border: 1px solid #CAD3DE;*!*/
        /*}*/

        .loginform .el-checkbox__label{
            color: #666666;
        }
        .loginform .el-checkbox__input.is-checked+.el-checkbox__label{
            color: #666666;
        }
        .loginform .el-form-item{
            width: 580px;
            height: 70px;
            display: flex;
            justify-content: left;
            align-items: center;
        }
        .loginform .el-form-item__content {
            width: 600px;
            align-items: center;
        }
        .nc-container .nc_wrapper, .nc-container.tb-login .nc_wrapper {
            width: 620px !important;
        }

        .nc-container .nc_scale{
            height: 60px;
        }
        .nc-container .nc_scale span{
            height: 58px !important;
            line-height: 58px !important;
        }
    }
</style>
